const state = () => ({
	data: {},
	isLoaded: false,
	bankAccModal: false,
	currentStage: 0,
})

// getters just GET values
const getters = {
	hasLoaded: state => {
		return state.data.id
	},
	allStagesData: state => {
		return state.data.stages
	},
	bankAccModalGetter: state => {
		return state.bankAccModal
	},
}

const mutations = {
	currentStageCommit(state) {
		state.currentStage = state.currentStage + 1
	},

	download(state, { whichStage, whichLetter }) {
		let query = state.data.stages[whichStage - 1].section[whichLetter - 1]
		if (query.progress < 2) {
			query.progress = query.progress + 1
		}
	},

	email(state, { whichStage, whichLetter }) {
		let query = state.data.stages[whichStage - 1].section[whichLetter - 1]
		query.progress = 3
	},

	completed(state, { whichStage, whichLetter }) {
		let query = state.data.stages[whichStage - 1].section[whichLetter - 1]
		if (query.progress < 3) {
			query.progress = 2
		} else {
			query.progress = 3
		}
		query.sectionCompleted = true
	},

	skip(state, { whichStage, whichLetter }) {
		let query = state.data.stages[whichStage - 1].section[whichLetter - 1]
		query.progress = 5
		query.sectionCompleted = true
	},

	sectionCompleted(state, { whichStage, whichLetter, response }) {
		let query = state.data.stages[whichStage - 1].section

		query.push({
			id: whichLetter + 1,
			progress: 1,
			sectionCompleted: false,
			startedOn: response.data.sectionStartedOn,
			completedOn: response.data.sectionCompleteOn,
		})
	},

	stageCompleted(state, { whichStage, response }) {
		let query = state.data.stages[whichStage - 1]
		query.completeDate = response.data.complete
	},

	getAllStagesData(state, data) {
		state.data = data
		state.isLoaded = true
	},

	startStage(state, { whichStage, response }) {
		let query = state.data
		query.stages.push({
			stageID: whichStage + 1,
			startDate: response.data.stageStartDate,
			completeDate: response.data.stageCompleteDate,
			nextStageAllowDate: response.data.stageNextStageAllowDate,
			section: [
				{
					id: 1,
					progress: 1,
					sectionCompleted: false,
					startedOn: response.data.sectionStartedOn,
					completedOn: response.data.sectionCompleteOn,
				},
			],
		})
	},
	finalStage(state) {
		state.data.stage = 3
	},
	bankAccModal(state) {
		state.bankAccModal = !state.bankAccModal
	},
}

// actions
const actions = {
	currentStageCommit(context){
		context.commit('currentStageCommit')
	},
	download(context, { whichStage, whichLetter }) {
		context.commit('download', { whichStage, whichLetter })
	},

	email(context, { whichStage, whichLetter }) {
		context.commit('email', { whichStage, whichLetter })
	},

	completed(context, { whichStage, whichLetter }) {
		context.commit('completed', { whichStage, whichLetter })
	},

	skip(context, { whichStage, whichLetter }) {
		context.commit('skip', { whichStage, whichLetter })
	},

	sectionCompleted(context, { whichStage, whichLetter, response }) {
		context.commit('sectionCompleted', { whichStage, whichLetter, response })
	},

	stageCompleted(context, { whichStage, response }) {
		context.commit('stageCompleted', { whichStage, response })
	},

	getAllStagesData(context, data) {
		context.commit('getAllStagesData', data)
	},

	completeStage(context, { whichStage, whichLetter, response }) {
		context.commit('completeStage', { whichStage, whichLetter, response })
	},
	startStage(context, { whichStage, response }) {
		context.commit('startStage', { whichStage, response })
	},

	bankAccModal(context) {
		context.commit('bankAccModal')
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
