<template>
	<a href="/my-profile" class="icon-wrapper pt-2">
		<div class="boxHeadshot">
			<img :style="{ 'border-color': ringColor }" src="/images/no-subscription_Logo.png" alt="Person Icon" v-if="ringColor === '#FFFFFF'"/>
			<img :style="{ 'border-color': ringColor }" src="/images/single-use_Logo.png" alt="Person Icon" v-if="ringColor === '#BD1D7A'"/>
			<img :style="{ 'border-color': ringColor }" src="/images/Bronze_Logo.png" alt="Person Icon" v-if="ringColor === '#CD7F32'"/>
			<img :style="{ 'border-color': ringColor }" src="/images/Silver_Logo.png" alt="Person Icon" v-if="ringColor === '#AAA9AD'"/>
			<img :style="{ 'border-color': ringColor }" src="/images/Gold_Logo.png" alt="Person Icon" v-if="ringColor === '#D7BE69'"/>
		</div>
	</a>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
	setup() {
		let test = ref(false)
		let store = useStore()
		let ringColor = computed(() => store.state.user.data.ringColor)

		return { test, ringColor }
	},
}
</script>

<style scoped>
img {
	box-shadow: none;
	padding: 17px 15px;
	padding-left: 17px;
	padding-right: 13px;
	border: none !important;
	border-radius: 0 !important;
	margin: 0 !important;
	background: none !important;
	width: 80px !important;
	height: 79px !important;
	text-align: center;
}
.boxHeadshot{
	box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
	border: 3px solid silver;
	border-radius: 60px;
	margin: auto;
	width: 80px;
	height: 80px;
	overflow: hidden;
	background: #30245d;
}
</style>
