import { createStore } from 'vuex'
import user from './modules/user'
import debtProcess from './modules/debtProcess'

export default createStore({
	state: {
		devUrl: 'https://api.debtshelf.co.uk/',
		showNav: false,
		debtId: null,
		modal1: false,
		modal2: false,
		modal3: false,
		modal4: false,
		uploadSuccess: false,
		urlEndPoint: 1,
		starRating: 0,
		fileIds: [],
	},
	getters: {
		uploadModalLetter1: state => {
			return state.modal
		},
	},
	mutations: {
		showNav(state) {
			state.showNav = true
		},
		toggleModal(state, whichModal) {
			let query = 'modal' + whichModal
			state[query] = !state[query]
		},
		uploadSuccess(state) {
			state.uploadSuccess = true
		},
		debtId(state, id) {
			state.debtId = id
		},
		setUrlEndpoint(state, num) {
			state.urlEndPoint = num
		},
		fileIds(state, arr) {
			state.fileIds = arr
		},
	},
	actions: {
		showNav(context) {
			context.commit('showNav')
		},
		debtId(context) {
			context.commit('debtId')
		},
		toggleModal(context, whichModal) {
			context.commit('toggleModal', whichModal)
		},
		uploadSuccess(context) {
			context.commit('uploadSuccess')
		},
		setUrlEndpoint(context, num) {
			context.commit('setUrlEndpoint', num)
		},
		fileIds(context, arr) {
			context.commit('fileIds', arr)
		},
	},
	modules: {
		user,
		debtProcess,
	},
})
