<template>
	<nav class="d-flex desktop" :class="{ 'd-none': !$store.state.user.data.name }">
		<div class="fixed">
			<div class="container">
				<div class="row h-100">
					<div class="col-6 col-xl-4 d-flex align-items-center">
						<a href="/"><img alt="Debtshelf logo" src="../../assets/logos/logowhite.svg"/></a>
					</div>
					<div class="col-6 col-xl-8">
						<div class="row h-100 justify-content-center">
							<div class="col-12 text-right">
								<!-- desktop -->
								<div class="row justify-content-end d-none d-lg-flex">
									<div class="d-flex align-items-center mr-5">
										<p class="mb-0 mr-3">
											<router-link to="/">My Accounts</router-link>
										</p>
										<p class="mb-0 mr-3">
											<router-link to="/my-profile">My Profile</router-link>
										</p>
										<p class="mb-0 mr-3">
											<router-link to="/support">Support</router-link>
										</p>
									</div>
									<div class="flex-column pt-3">
										<p class="mb-0 name">{{ getUserInfo.name }}</p>
										<p class="mb-0 amount">Debt Amount: £{{ getUserInfo.debtAmount }}</p>
										<p class="mb-0 debts">{{ getUserInfo.debtLive }} live debts</p>
									</div>
									<Headshot class="pl-3 navbarHeadshot" />
								</div>
								<!-- mobile -->
								<div class="row justify-content-end d-flex d-lg-none">
									<div id="nav-icon1" :class="{ open: isActive }" @click="isActive = !isActive">
										<span></span>
										<span></span>
										<span></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>

	<div id="mobile-menu" :class="{ 'd-none': !isActive }">
		<ul>
			<!-- Check Router to change value --><!--v-if-->
			<li>
				<a href="/" class="row m-0 align-items-center">
					<img src="/images/icon.svg" alt="" />
					<p>My Accounts</p>
				</a>
			</li>
			<li>
				<a href="/my-profile" class="row m-0 align-items-center active"
				><i class="fas fa-user"></i>
				<p>My Information</p></a
				>
			</li>
			<li>
				<a href="/subscription" class="row m-0 align-items-center"
				><i class="fas fa-receipt"></i>
				<p>Subscriptions</p></a
				>
			</li>
			<li>
				<a href="/support" class="row m-0 align-items-center"
				><i class="fas fa-headset"></i>
				<p>Support</p></a
				>
			</li>
			<li>
				<a class="row m-0 cursor"
				><i class="fas fa-sign-out-alt"></i>
				<p>Sign out</p></a
				>
			</li>
		</ul>
	</div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useStore, mapGetters } from 'vuex'
import Headshot from '../../components/Snippets/Headshot'

export default {
	computed: {
		...mapGetters('debtProcess', ['hasLoaded', 'allStagesData']),
	},
	setup() {
		let isActive = ref(false)
		const store = useStore()
		const getUserInfo = computed(() => store.state.user.data)

		// Watch the data
		// when it chamges run the arrow function
		watch(isActive, () => {
			let sitebody = document.body
			isActive.value ? sitebody.classList.add('disable-scroll') : sitebody.classList.remove('disable-scroll')
		})
		return { isActive, getUserInfo, Headshot }
	},
}
</script>

<style lang="scss">
nav {
	display: flex;
	min-height: 100px;
	a,
	p {
		color: #fff;
		transition: all ease 0.3s;
	}
	a:hover {
		color: rgb(221, 221, 221);
		text-decoration: none;
	}
	.fixed {
		width: 100%;
		position: fixed;
		top: 0;
		background: blue;
		background: transparent
		linear-gradient(
		180deg,
		#30255e 0%,
		#2f2862 10%,
		#2d2c67 20%,
		#2b316d 30%,
		#293774 40%,
		#273a78 45%,
		#263c7b 50%,
		#234383 60%,
		#20498b 70%,
		#1c5094 80%,
		#19579d 90%,
		#145fa7 100%
		)
		0% 0% no-repeat padding-box;
		height: 100px;
		z-index: 9;
	}
	.container {
		margin: auto;
		height: 100%;
	}
	.row {
		height: 100%;
	}
	.icon-wrapper img {
		border: 3px solid silver;
		border-radius: 60px;
	}
}

#mobile-menu {
	position: fixed;
	top: 0;
	background: white;
	width: 100%;
	height: 100vh;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	ul {
		padding: 0px;
		margin-bottom: 0px;
		li {
			list-style: none;
			font-size: 24px;
			text-align: center;
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0px;
			}
			p,
			a,
			i {
				color: $darkBlue;
			}
			p {
				margin-bottom: 0px;
				margin-left: 15px;
			}
		}
	}
}
</style>

<style lang="scss">
/* Icon 1 */

#nav-icon1 {
	width: 60px;
	height: 45px;
	position: relative;
	top: 30px;
	left: -15px;
	color: #fff;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
	z-index: 2;
}

#nav-icon1 span {
	display: block;
	position: absolute;
	height: 4px;
	width: 50px;
	background: #fff;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

#nav-icon1.open span {
	background: #fff;
}

#nav-icon1 span:nth-child(1) {
	top: 10px;
}

#nav-icon1 span:nth-child(2) {
	top: 22px;
}

#nav-icon1 span:nth-child(3) {
	top: 34px;
}

#nav-icon1.open span:nth-child(1) {
	top: 18px;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
	opacity: 0;
	left: -60px;
}

#nav-icon1.open span:nth-child(3) {
	top: 18px;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
</style>
