import { createRouter, createWebHistory } from 'vue-router'
import 'axios'

import store from '@/store/index.js'
import axios from 'axios'

const checkToken = (to, from, next, endpoint) => {
	axios({
		method: 'get',
		url: store.state.devUrl + 'auth',
		headers: {
			'content-type': 'application/json',
			authorization: `Bearer ${localStorage.token}`,
		},
	})
		.then(result => {
			store.commit('user/userInfo', {
				name: result.data.fullName,
				debtLive: result.data.debtLive,
				debtAmount: result.data.debtAmount,
				isUserAdmin: result.data.isUserAdmin,
				hasBankDetail: result.data.hasBankDetail,
				ringColor: result.data.ringColor,
			})
			next(endpoint)
		})
		.catch(err => {
			console.log(err)
			localStorage.token = ''
			next('/login')
		})
}

function authorizated(to, from, next) {
	localStorage.token ? checkToken(to, from, next, '/') : next()
}

function prohibited(to, from, next) {
	localStorage.token ? checkToken(to, from, next) : next('/login')
}

const routes = [
	{
		path: '/test',
		name: 'Test',
		component: () => import('../views/test.vue'),
	},
	//  auth
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/auth/Layout.vue'),
		beforeEnter: authorizated,
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/auth/Layout.vue'),
		beforeEnter: authorizated,
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: () => import('../views/auth/Layout.vue'),
	},
	{
		path: '/change-password',
		name: 'ChangePassword',
		component: () => import('../views/auth/Layout.vue'),
	},

	//Dashboard
	{
		path: '/',
		name: 'MyDebts',
		component: () => import('../views/dashboard/Dashboard.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/create-debt',
		name: 'CreateDebt',
		component: () => import('../views/dashboard/Dashboard.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/debt/:id',
		name: 'DebtProcess',
		component: () => import('../views/DebtProcess/Debt.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/my-profile',
		name: 'MyInfo',
		component: () => import('../views/dashboard/Dashboard.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/subscription',
		name: 'Subscription',
		component: () => import('../views/dashboard/Dashboard.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/subscription/success',
		name: 'SubscriptionSuccess',
		component: () => import('../views/dashboard/Dashboard.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/subscription/canceled',
		name: 'CanceledSubscription',
		component: () => import('../views/dashboard/Dashboard.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/support',
		name: 'ContactPreferences',
		component: () => import('../views/dashboard/Dashboard.vue'),
		beforeEnter: prohibited,
	},
	// Inject:Admin
	{
		path: '/admin',
		name: 'AdminDashboard',
		component: () => import('../views/Admin/Overview.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/admin/feedback',
		name: 'AdminFeedback',
		component: () => import('../views/Admin/Overview.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/admin/templates',
		name: 'AdminTemplates',
		component: () => import('../views/Admin/Overview.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/admin/templates/create',
		name: 'AdminCreateTemplate',
		component: () => import('../views/Admin/Overview.vue'),
		beforeEnter: prohibited,
	},
	{
		path: '/admin/templates/edit',
		name: 'AdminEditTemplate',
		component: () => import('../views/Admin/Overview.vue'),
		beforeEnter: prohibited,
	},
	// Inject:404
	{
		// path: "*",
		path: '/:catchAll(.*)',
		name: 'NotFound',
		component: () => import('../views/404.vue'),
		meta: {
			requiresAuth: false,
		},
	},
]

const router = createRouter({
	history: createWebHistory('/'),
	routes,
})

export default router
