const state = () => ({
	loaded: false,
	data: {
		name: '',
		debtLive: 0,
		isUserAdmin: true,
		debtAmount: '',
	},
	profile: {},
})

const mutations = {
	userInfo(state, data) {
		state.data = data
	},
	profileInfo(state, data) {
		state.data = data
		state.loaded = data
	},
}

// actions
const actions = {
	userInfo(context, user) {
		context.commit('userInfo', user)
	},
	profileInfo(context, user) {
		context.commit('profileInfo', user)
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
