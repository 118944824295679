import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { captureException, init, setTag } from '@sentry/browser'

// All CSS libraries
import './scss/_variables.scss'
import 'bootstrap/dist/css/bootstrap.min.css'

const app = createApp(App)
	.use(store)
	.use(router)

Sentry.init({
	app,
	release: 'debtshelf@1.0.1',
	dsn: 'https://d863bd5d5dea4c23b13668fd31999a87@o568633.ingest.sentry.io/5713902',
	integrations: [new Integrations.BrowserTracing()],
	environment: process.env.NODE_ENV,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
	// child components, and see more details about the rendering process
	tracingOptions: {
		trackComponents: true,
	},
})

// * Please READ:
// https://github.com/getsentry/sentry-javascript/issues/2925#issuecomment-797390878

app.config.errorHandler = (error, _, info) => {
	setTag('info', info)
	captureException(error)
}

init({ dsn: 'https://d863bd5d5dea4c23b13668fd31999a87@o568633.ingest.sentry.io/5713902' })

app.mount('#app')
