<template>
	<div>
		<Navbar v-if="hide && show" />
		<router-view />
		<Footer v-if="hide && show" />
	</div>
</template>

<script>
	import Navbar from './components/layout/Nav'
	import Footer from './components/layout/Footer'
	import { ref, onMounted, computed } from 'vue'
	import { useStore } from 'vuex'

	export default {
		components: { Navbar, Footer },
		setup() {
			const hide = ref(true)
			const store = useStore()

			onMounted(() => {
				const url = window.location.href

				const getUrl = x => {
					if (url.split('/')[url.split('/').length - 1] === x) {
						hide.value = true
					}
					if (store.state.user.data.name !== '') {
						store.commit('showNav')
					}
				}

				getUrl('register')
				getUrl('login')
				getUrl('reset-password')
			})
			return { hide, show: computed(() => store.state.showNav) }
		},
	}
</script>

<style lang="scss">
	@font-face {
		font-family: 'Cocon';
		src: url('../src/assets/fonts/Cocon-RegularTR.woff') format('woff');
	}

	@media only screen and (min-width: 1500px) {
		#app {
			.container {
				max-width: 1500px;
			}
		}
	}

	@media only screen and (max-width: 1023px) {
		form.mt-5.w-75.mx-auto {
			.row {
				margin: 0px;
			}
		}
	}

	.disable-scroll {
		overflow: hidden;
	}

	#app {
		font-family: 'Cocon', Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: $darkBlue;
		h1,
		h2,
		h3,
		h4,
		h5 {
			font-weight: bold;
			font-family: 'Cocon', Avenir, Helvetica, Arial, sans-serif;
		}
		div,
		p,
		span,
		li {
			font-family: 'Poppins', sans-serif;
		}
		img {
			max-width: 100%;
		}
		.fade-enter-active,
		.fade-leave-active {
			transition-duration: 0.6s;
			transition-property: opacity;
			transition-timing-function: ease;
		}

		.fade-enter,
		.fade-leave-active {
			opacity: 0;
		}

		.blue-border {
			border: 1px solid $darkBlue;
			padding: 10px 5px;
			padding-left: 30px;
			margin-bottom: 15px;
			border-radius: 14px;

			width: 100%;
		}
		.blue-border:focus {
			outline: none;
		}
		.percentage {
			position: relative;
		}
		.percentage:after {
			content: '%';
			position: absolute;
			top: 12px;
			right: 20px;
		}
		button.btn {
			transition: all ease 0.3s;
			padding: 10px 5px;
		}
		button.btn:hover {
			background: $darkBlue;
			color: #fff;
		}
		.disabled {
			opacity: 0.5;
		}
	}

	#nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: $darkBlue;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}

	#loading-wrapper {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	#loading-text {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		color: $darkBlue;
		width: 100px;
		height: 30px;
		margin: -7px 0 0 -45px;
		text-align: center;
		font-family: 'PT Sans Narrow', sans-serif;
		font-size: 20px;
	}

	#loading-content {
		display: block;
		position: relative;
		left: 50%;
		top: 50%;
		width: 170px;
		height: 170px;
		margin: -85px 0 0 -85px;
		border: 3px solid #f00;
	}

	#loading-content {
		border: 3px solid transparent;
		border-top-color: rgb(121, 61, 185);
		border-bottom-color: rgb(121, 61, 185);
		border-radius: 50%;
		-webkit-animation: loader 2s linear infinite;
		-moz-animation: loader 2s linear infinite;
		-o-animation: loader 2s linear infinite;
		animation: loader 2s linear infinite;
	}

	@keyframes loader {
		0% {
			-webkit-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
			-ms-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
</style>
