<template>
	<div class="fusion-footer">
		<footer class="fusion-footer-widget-area fusion-widget-area">
			<div class="fusion-row container p-0">
				<div class="fusion-columns fusion-columns-6 fusion-widget-area">
					<div class="fusion-column col-lg-2 col-md-2 col-sm-2">
						<section
							id="media_image-2"
							class="fusion-footer-widget-column widget widget_media_image"
							style="border-style: solid;border-color:transparent;border-width:0px;"
						>
							<img
								width="232"
								height="33"
								src="https://www.debtshelf.co.uk/wp-content/uploads/2020/06/logo.png"
								class="image wp-image-31  attachment-full size-full"
								alt="logo"
								style="max-width: 100%; height: auto;"
								srcset="
									https://www.debtshelf.co.uk/wp-content/uploads/2020/06/logo-200x28.png 200w,
									https://www.debtshelf.co.uk/wp-content/uploads/2020/06/logo.png        232w
								"
								sizes="(max-width: 232px) 100vw, 232px"
							/>
							<div style="clear:both;"></div>
						</section>
					</div>
					<div class="fusion-column col-lg-2 col-md-2 col-sm-2">
						<section
							id="text-2"
							class="fusion-footer-widget-column widget widget_text"
							style="border-style: solid;border-color:transparent;border-width:0px;"
						>
							<div class="textwidget">
								<p><strong>PRODUCT</strong></p>
								<p>Platform</p>
								<p>Solutions</p>
								<p>Case studies</p>
								<p><a href="#CardSection">Pricing</a></p>
								<p>FAQs</p>
							</div>
							<div style="clear:both;"></div>
						</section>
					</div>
					<div class="fusion-column col-lg-2 col-md-2 col-sm-2">
						<section
							id="text-3"
							class="fusion-footer-widget-column widget widget_text"
							style="border-style: solid;border-color:transparent;border-width:0px;"
						>
							<div class="textwidget">
								<p><strong>DATA</strong></p>
								<p>Data</p>
								<p>Blog</p>
								<p>Research Data</p>
							</div>
							<div style="clear:both;"></div>
						</section>
					</div>
					<div class="fusion-column col-lg-2 col-md-2 col-sm-2">
						<section
							id="text-4"
							class="fusion-footer-widget-column widget widget_text"
							style="border-style: solid;border-color:transparent;border-width:0px;"
						>
							<div class="textwidget">
								<p><strong>COMPANY</strong></p>
								<p>About</p>
								<p>Jobs</p>
								<p>Contact</p>
								<p>Research &amp; Consultancy</p>
								<p>Customer support</p>
								<p>Press</p>
							</div>
							<div style="clear:both;"></div>
						</section>
					</div>
					<div class="fusion-column col-lg-2 col-md-2 col-sm-2">
						<section
							id="text-5"
							class="fusion-footer-widget-column widget widget_text"
							style="border-style: solid;border-color:transparent;border-width:0px;"
						>
							<div class="textwidget">
								<p><strong>LEGAL</strong></p>
								<p>Privacy Notice</p>
								<p>Data Policy</p>
								<p>
									<a href="https://www.debtshelf.co.uk/terms-conditions">Terms and Conditions</a>
								</p>
								<p>Cookies</p>
							</div>
							<div style="clear:both;"></div>
						</section>
					</div>
					<div
						class="fusion-column fusion-column-last col-lg-2 col-md-2 col-sm-2 text-right"
					>
						<div class="socail-wrapper">
							<a href="" target="_blank"> <i class="fab fa-linkedin"></i></a>
							<a href="" target="_blank"> <i class="fab fa-instagram"></i></a>
							<a href="" target="_blank"> <i class="fab fa-twitter"></i></a>
							<a href="" target="_blank"> <i class="fab fa-facebook-f"></i></a>
						</div>
					</div>

					<div class="fusion-clearfix"></div>
				</div>
				<!-- fusion-columns -->
			</div>
			<!-- fusion-row -->
		</footer>
		<!-- fusion-footer-widget-area -->

		<footer
			id="footer"
			class="fusion-footer-copyright-area fusion-footer-copyright-center"
		>
			<div class="fusion-row">
				<div class="fusion-copyright-content">
					<div class="fusion-copyright-notice" style="padding-bottom: 0px;">
						<div>
							<a
								href="https://www.123internet.agency/web"
								alt="Web Design Milton Keynes | Northampton"
								title="Web Design Milton Keynes | Northampton"
								>Website Design by 123internet</a
							>
							-
							<a
								href="https://clockwork-studios.com/"
								alt="Social Media Milton Keynes | Northampton"
								title="Social Media Milton Keynes | Northampton"
								>Development by Clockwork studios</a
							>
						</div>
					</div>
				</div>
				<!-- fusion-fusion-copyright-content -->
			</div>
			<!-- fusion-row -->
		</footer>
		<!-- #footer -->
	</div>
</template>

<style lang="scss" scoped>
	.fusion-row {
		max-width: 1200px;
		margin: auto;
		a {
			color: #fff;
		}
	}
	.fusion-footer .fusion-footer-widget-area {
		background-color: #efefef !important;
		background-image: linear-gradient(
			180deg,
			#30255e 0%,
			#145fa7 100%
		) !important;
	}
	.fusion-footer .fusion-footer-widget-area {
		border-top-right-radius: 125px !important;
	}
	.fusion-footer img {
		margin-bottom: 60px;
	}
	.fusion-footer {
		background-color: #efefef !important;
		--footer_area_padding-top: 60px;
		--footer_area_padding-bottom: 15px;
		--footer_area_padding-left: 0px;
		--footer_area_padding-right: 0px;
		--footer_divider_line-flex: block;
		--footer_divider_line_size: 1px;
		--footer_divider_line_style: solid;
		--footer_widgets_padding: 16px;
	}
	.fusion-footer-widget-area {
		position: relative;
		padding: 60px 10px 15px;
		border-top: 0px solid #e2e2e2;
		background-repeat: no-repeat;
		background-color: #30245d;
		background-size: initial;
		background-position: center center;
		color: #ffffff;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}
	.fusion-footer footer .fusion-row .fusion-columns {
		display: block;
		-ms-flex-flow: wrap;
		flex-flow: wrap;
	}
	.fusion-columns-6 .fusion-column:nth-of-type(6n + 1),
	.fusion-columns-6 .fusion-flip-box-wrapper:nth-of-type(6n + 1) {
		clear: both;
	}
	.fusion-footer footer .fusion-row .fusion-columns {
		display: flex;
	}
	.fusion-footer-copyright-area {
		z-index: 10;
		position: relative;
		padding: 25px 10px 25px;
		border-top: 0px solid #26303e;
		background-color: #30245d;
	}
	.fusion-footer-copyright-area .fusion-row {
		max-width: 1200px;
		margin: auto;
	}
	.fusion-footer-copyright-center .fusion-copyright-content {
		display: block;
		text-align: center;
		color: #fff;
	}
	.fusion-body .fusion-footer-copyright-center .fusion-copyright-notice {
		display: block;
		width: 100%;
		text-align: center;
	}
	.fusion-copyright-notice a {
		color: rgba(255, 255, 255, 0.8);
	}
	.socail-wrapper i {
		font-size: 24px;
		padding: 0px 10px;
	}
</style>
